import request from '@/utils/request';

export function companyNatures(params) {
  return request({
    url: 'companyNatureApi/getCompanyNatures',
    method: 'get',
    params,
  });
}


export default { companyNatures };
