<template>
  <div class="bidStep">
    <div class="apply">
      投标保函申请
    </div>
    <div class="apply-con">
      <div>
        <a-form-model
          :model="form"
          :label-col="{xs: { span: 24 },sm: { span: 3 }}"
          :wrapper-col="{xs: { span: 24 },sm: { span: 12 }}"
        >
          <div class="tit">
            投标信息
          </div>
          <a-form-model-item label="投标人名称">
            <div>XXX公司</div>
          </a-form-model-item>
          <a-form-model-item label="社会统一信用代码">
            <div>91410100MA46ULUCX4</div>
          </a-form-model-item>
          <a-form-model-item label="投标人手机号">
            <div>133000000001</div>
          </a-form-model-item>
          <a-form-model-item label="保证金金额">
            <div>5555元</div>
          </a-form-model-item>
          <a-form-model-item label="支付截止时间">
            <div>2022-03-20 15:44:00</div>
          </a-form-model-item>
          <div class="tit">
            手机号验证
          </div>
          <a-form-model-item
            label="手机号"
            prop="phoneNum"
          >
            <a-input v-model="form.phoneNum" />
          </a-form-model-item>
          <a-form-model-item
            label="验证码"
            prop="code"
          >
            <div style="display:flex;">
              <a-input v-model="form.code" />
              <img
                :src="codeImg"
                alt=""
                style="width: 100px;height: 35px;margin-left: 16px;"
                @click="getImgcode"
              >
            </div>
          </a-form-model-item>
          <a-form-model-item
            label="手机验证码"
            prop="code1"
          >
            <a-input v-model="form.code1" />
            <a-button
              type="link"
              v-if="isTime"
              @click="getCode"
            >
              获取手机验证码
            </a-button>
            <span
              v-else
              style="margin-left:20px"
            >{{ time }}s重新获取</span>
          </a-form-model-item>
          <div class="tit">
            手机号验证
          </div>
          <a-form-model-item
            label="投标人地址"
            prop="code2"
          >
            <a-input v-model="form.code2" />
          </a-form-model-item>
          <a-form-model-item
            label="联系人"
            prop="code3"
          >
            <a-input v-model="form.code3" />
          </a-form-model-item>
          <a-form-model-item
            label="单位性质"
            prop="code4"
          >
            <a-select
              v-model="form.code4"
              placeholder=""
            >
              <a-select-option value="male">
                male
              </a-select-option>
              <a-select-option value="female">
                female
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="bidBtn">
            <a-button
              type="primary"
              @click="handleSubmit"
            >
              下一步
            </a-button>
            <a-button>
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- <a-form
          :form="forms"
          :label-col="{xs: { span: 24 },sm: { span: 3 }}"
          :wrapper-col="{xs: { span: 24 },sm: { span: 12 }}"
          @submit="handleSubmit"
        >
          <div class="tit">
            投标信息
          </div>
          <a-form-item label="投标人名称">
            <div>XXX公司</div>
          </a-form-item>
          <a-form-item label="社会统一信用代码">
            <div>91410100MA46ULUCX4</div>
          </a-form-item>
          <a-form-item label="投标人手机号">
            <div>133000000001</div>
          </a-form-item>
          <a-form-item label="保证金金额">
            <div>5555元</div>
          </a-form-item>
          <a-form-item label="支付截止时间">
            <div>2022-03-20 15:44:00</div>
          </a-form-item>
          <div class="tit">
            手机号验证
          </div>
          <a-form-item label="手机号">
            <a-input v-decorator="['phoneNum']" />
          </a-form-item>
          <a-form-item
            label="
              验证码"
          >
            <div style="display:flex;">
              <a-input v-decorator="['code']" />
              <img
                :src="codeImg"
                alt=""
                style="width: 100px;height: 35px;margin-left: 16px;"
                @click="getImgcode"
              >
            </div>
          </a-form-item>
          <a-form-item label="手机验证码">
            <a-input v-decorator="['code1']" />
            <a-button
              type="link"
              v-if="isTime"
              @click="getCode"
            >
              获取手机验证码
            </a-button>
            <span
              v-else
              style="margin-left:20px"
            >{{ time }}s重新获取</span>
          </a-form-item>
          <div class="tit">
            手机号验证
          </div>
          <a-form-item label="投标人地址">
            <a-input v-decorator="['code2']" />
          </a-form-item>
          <a-form-item label="联系人">
            <a-input v-decorator="['code3']" />
          </a-form-item>
          <a-form-item label="单位性质">
            <a-select
              v-decorator="[
                'code4',
                { rules: [{ required: false, message: '请选择单位性质' }] },
              ]"
              placeholder=""
            >
              <a-select-option value="male">
                male
              </a-select-option>
              <a-select-option value="female">
                female
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="bidBtn">
            <a-button
              type="primary"
              html-type="submit"
            >
              下一步
            </a-button>
            <a-button>
              取消
            </a-button>
          </a-form-item>
        </a-form> -->
      </div>
    </div>
  </div>
</template>
<script>
import { graphicsCode, smsCode } from '@/api/code.js';
import { companyNatures } from '@/api/guaranteeServe/apply.js';
export default {
  data() {
    return {
      codeImg: '',
      isTime: true,
      time: 10,
      form: {
        com: '',
        code: '',
        phoneNum: '',
        code1: '',
        code2: '',
        code3: '',
        code4: '',
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getImgcode();
      // this.getCompanyNatures();
    },
    getImgcode() {
      graphicsCode({ source: 'ACC_USER_LOGIN' }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    getCode() {
      console.log(this.form.phoneNum, this.form.code);
      this.timer = '';
      if (this.form.code !== '' && this.form.phoneNum !== '' && !!this.form.code) {
        smsCode({ source: 'ACC_USER_LOGIN', checkCode: this.form.code, phoneNum: this.form.phoneNum }).then(res => {
          this.isCode = res.data;
          if (res.data.status === 'FAIL') {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('手机号码和图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    getCompanyNatures() {
      companyNatures().then(res => {
        console.log(res);
      });
    },
    handleSubmit() {
      console.log(this.form, 'this.form+++');
      // e.preventDefault();
      // this.forms.validateFields((err, values) => {
      //   if (!err) {
      //     this.form = { ...values };
      //     this.form.con = 'XXX公司';
      //     console.log('Received values of form: ', values, this.form);
      //   }
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.bidStep {
  padding: 30px 0 30px 150px;
  min-width: 1400px;
}
.apply {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 30px;
}
.apply-con {
  margin-left: 50px;
  .tit {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 30px;
  }
}
.bidBtn {
  ::v-deep .ant-form-item-control {
    left: 50%;
  }
  ::v-deep .ant-btn {
    width: 100px;
    height: 40px;
    margin-right: 50px;
  }
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-form-item {
  margin-left: 200px;
}
::v-deep .ant-input,
.ant-select {
  width: 350px;
}
</style>
